/* @media only screen and (max-width: 450px) { */
@media only screen and (max-width: 550px) {
  .text {
    padding-bottom: 20px;
  }
}

@media only screen and (min-width: 551px)  and (max-width: 1024px) {
  .text {
    padding-bottom: 20px;
    /* border: 1px solid black; */
  }

}


@media only screen and (max-width: 759px) {
  .skillContainer {
    row-gap: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10%;
  }

  .headingUnderline {
    margin-top: 15px;
    width: 75px;
    height: 2px;
    background-color: var(--deep-yellow)
  }

  .text {
    grid-template-rows: 1fr 1fr 1fr;
    display: grid;
    row-gap: 20px;
  }

  .skillContainer section {
    display: grid;
    grid-template-columns: 1fr 3fr ;
  }

  .details {
    padding: 0 20%;
    border-left: 2px solid var(--less-deep-dark);
  }

  .text h3 {
    color: var(--deep-yellow);
  }
}

@media only screen and (min-width: 760px) and (max-width: 1024px){
 .skillContainer {
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    padding-left: 10%;
    /* justify-content: center; */
  } 

  .skillContainer h1 {
    font-size: 3rem;
  }

  .text h3 {
    font-size: 2rem;
  }

  .skillContainer header {
    padding: 5% 0 10% 0;
  }
 .headingUnderline {
    margin-top: 15px;
    width: 75px;
    height: 2px;
    background-color: var(--deep-yellow)
  }

  .text {
    grid-template-rows: 1fr 1fr 1fr;
    display: grid;
    row-gap: 20px;
  }

  .skillContainer section {
    display: grid;
    grid-template-columns: 1fr 3fr ;
  }

  .details {
    padding: 0 20%;
    border-left: 2px solid var(--less-deep-dark);
  }

  .text h3 {
    color: var(--deep-yellow);
  }
}

@media only screen and  (min-width: 1025px) {
  .skillContainer {
    display: grid;
    grid-template-rows: 1fr 4fr;
    /* justify-content:baseline; */
    /* cancel out the 10% effect set previously in the default */
    padding: 0 5%; 
    /* addition below ...  */
    height: 100vh;
  }

  .skillContainer h1 {
    font-size: 50px; 
    /* text-decoration: underline;   
    text-decoration-color: var(--deep-yellow); */
  }

  .skillContainer h1 {
    width: 5%;
    padding-bottom: 2%;
    border-bottom: 2px solid var(--deep-yellow);
  }


  .text {
    font-size: 38px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 3%;
  }

  .text section {
    display: flex;
    flex-direction: column;
  }

  .details {
    border-top: 2px solid var(--less-deep-dark);
    font-size: 22px;
    display: flex;
    flex-direction: column;
  }
  .text h3 {
    color: var(--deep-yellow);
  }

}

@media only screen and  (min-width: 1500px) {
  /* .skillContainer h1 {
    font-size: 5rem;
  }

  .text h3 {
    font-size: 3.5rem;
  }

  .details p {
    font-size: 2rem;
  } */
}



/* @media only screen and (min-width: 760px) {
  .skillContainer {
    box-sizing: border-box;
  }

  .skillContainer header {

    display: flex;
    flex-direction: column;
  }

  .skillContainer h1 {
    font-size: 50px;
  }

  .headingUnderline {
    width: 100px;
  }

  .skillContainer p {
    font-size: 20px;
    font-weight: 900;
  }

  .skillContainer h3 {
    font-size: 40px;
  }

  .skillContainer section {
    grid-template-columns: 2fr 1fr 3fr;
  }
} */



