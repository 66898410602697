@media only screen and (min-width: 551px)  and (max-width: 1024px) and (orientation: landscape){
  .cloud {
    padding-top: 30%;
    /* border: 1px solid red; */
  }
}

@media only screen and (min-width: 1025px ) {
  .cloud {
    font-size: 1.5em;
  }
}

