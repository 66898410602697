.odd-page {
  /* height: 100vh; */
  background-color: var(--less-deep-dark);
  color: var(--deep-yellow);
}

.even-page {
  /* height: 100vh; */
  background-color: var(--very-pale-yellow);
  color: var(--less-deep-dark);
}

.top-btn {
  position: fixed;
  bottom: 6%;
  left: 2%;
  z-index: 2;
}

.navbar_link__IEUhR {
  color: inherit;
}

.dot {
  height: 2em;
  width: 2em;
  /* height: 100%;
  width: 100%; */
  background-color: var(--deep-yellow);
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  opacity: 0.4;
  transition: 1s;
}

.dot:hover {
  opacity: 1;
  height: 3em;
  width: 3em;
}


/* CSS ADDED BY GOLAK 01-09-2022 */

@media (max-width: 1024px) {
  .even-page,
  .odd-page {
    height: 100%;
  }
  .projects_contentContainer__AJoY7 {
    gap: 40px;
    padding-block: 40px;
  }
  .aboutme_aboutContainer__VTR3r {
    padding-block: 20px 40px;
  }
  .aboutme_aboutContainer__VTR3r main {
    gap: 40px;
  }
  .techstack_skillContainer__lG4P3 {
    padding-block: 40px;
  }
  .home_mainContent__VOZ2o{
    height: calc(100% - 104px);
  }

}




