/* @media only screen and (max-width: 450px) { */
@media only screen and (max-width: 550px) {
  .homeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .mainContent {
    /* border: 1px solid white; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .mainContent h1 {
    padding-top: 25%;
  }
}

@media only screen and (min-width: 551px)  and (max-width: 1024px) {
  .homeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .mainContent {
    /* border: 1px solid white; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .mainContent h1 {
    padding-top: 25%;
  }
}

/* pre-historical below ... */
.mainContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-evenly; */
  justify-content: center;
  /* border: 1px solid white; */
}

.mainContent h1 {
  font-size: 30px;
}



.mainContent p {
  font-size: 22px;
}


@media only screen and (min-width: 760px )  {
  .homeContainer h1 {
    font-size: 50px;
    padding-top: 0;
    /* margin-bottom: -30px; */
  }

  .homeContainer p {
    font-size: 40px;
  }
}


@media only screen and  (min-width: 1025px) {
  .mainContent {
    height: 80vh;
  }
  .mainContent h1 {
    font-size: 38px;
  }
  /* .mainContent h1 {
    font-size: 33px;
  }
  .mainContent p {
    font-size: 22px;
  } */
}

.homeContainer{
  height: 100vh;
}
@media(max-width:1024px){
@media screen and (orientation: landscape) {
  .homeContainer{
    height: 100%;
  }
}
}