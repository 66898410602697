/* @media only screen and (max-width: 450px) { */
@media only screen and (max-width: 550px) {
  .imgContainer {
    padding-bottom: 20px;
  }
}

@media only screen and (min-width: 551px) and (max-width: 1024px) {
  .imgContainer {
    padding: 30px 0;
  }
}

.aboutContainer main {
  padding: 0 10%;
  display: flex;
  justify-content: space-around;
  /* align-items: center;  */
  flex-direction: column;
  height: 90%;
}

.aboutContainer h2 {
  text-align: center;
  margin-top: 0%;
  padding-top: 8%;
}

.textContainer {
  color: var(--very-pale-yellow);
}

.textContainer h3 {
  text-align: left;
  /* font-size: 1.4rem; */
  font-size: 1.4em;
}

.textContainer li {
  font-size: 1.25rem;
  /* margin-top: 1.5rem; */
  margin-top: 1.5em;
}

.arrowContainer {
  position: relative;
  top: 5px;
  left: 5px;
  transition: top 0.1s;
}

.textHeading:hover {
  color: var(--deep-yellow);
  cursor: pointer;
}

.textHeading:hover .arrowContainer {
  top: -2px;
}

.imgContainer {
  text-align: center;
}

.imgContainer img {
  width: 60%;
  border-radius: 50%;
}

@media only screen and (min-width: 760px) {
  .imgContainer img {
    width: 50%;
  }
  .aboutContainer h2 {
    font-size: 55px;
    margin-bottom: 0;
  }

  .textContainer p {
    font-size: 22px;
  }
}

@media only screen and (min-width: 1310px) {
  /* .textContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  } */

  .imgContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

@media only screen and (min-width: 1025px) {
  .aboutContainer {
    display: flex;
    flex-direction: column;
    /* addition below ...  */
    height: 100vh;
  }

  .aboutContainer main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10%;
  }

  .aboutContainer h2 {
    margin-top: 0%;
    padding: 3% 0;
  }

  .imgContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .imgContainer img {
    width: 70%;
  }
}
