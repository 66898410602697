@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@200;300;400&display=swap");

body {
  margin: 0;
  font-family: "League Spartan", sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --deep-dark: #161616;
  --less-deep-dark: #282828;
  --deep-yellow: #f1b630;
  --very-pale-yellow: #faf3dd;
  --transition: all 0.1s linear;
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  width: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
