@media only screen and (max-width: 550px) {
  .navContainer {
    position: absolute;
    top: 0;
    left: 0;
  }
}

@media only screen and (min-width: 551px) and (max-width: 1024px) {
  .navContainer {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.navContainer {
  background: var(--deep-dark);
  /* color: var(--deep-yellow); */
  color: var(--very-pale-yellow);
  width: 100%;
  padding: 15px 0;
  display: flex;
  justify-content: space-around;
  z-index: 100;
  border-bottom: 1px solid var(--very-pale-yellow);
}

.box {
  background-color: var(--less-deep-dark);
  padding: 10px;
  width: 65px;
  border-radius: 10px;
}

.icon {
  font-size: 35px;
  padding-bottom: 10px;
}

.link {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: var(--very-pale-yellow);
}

.link:hover {
  color: var(--less-deep-dark);
}

.link:visited {
  color: inherit;
}

/* @media only screen and (min-width: 811px ) {
  .box {
    width: 120px;
    font-size: 30px;
  }

  .icon {
    font-size: 50px;
  }
} */

@media only screen and (min-width: 551px) {
  /* @media only screen and (min-width: 1025px ) { */
  .navContainer {
    justify-content: flex-start;
    padding: 15px 0 10px 0;
  }

  .link {
    display: flex;
    flex-direction: row;
  }
  .box {
    background-color: inherit;
    margin-right: 3%;
    transition: 1s;
    width: fit-content;
  }

  .box:nth-child(1) {
    margin-left: 1%;
  }

  .box:hover {
    background-color: var(--very-pale-yellow);
    color: var(--less-deep-dark);
  }

  .wrd {
    font-size: 22px;
  }
}
