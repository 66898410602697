/* @media only screen and (max-width: 450px) { */
@media only screen and (max-width: 550px) {
  .textDetails h2 {
    font-size: 20px;
  }
  .contentContainer {
    /* border: 1px solid black; */
    padding-top: 10px;
  }

  .imgContainer {
    padding-bottom: 30px;
  }
}

@media only screen and (min-width: 551px) and (max-width: 1024px) {
  .textDetails h2 {
    font-size: 20px;
  }
  .contentContainer {
    /* border: 1px solid black; */
    padding-top: 10px;
  }

  .imgContainer {
    padding-bottom: 30px;
  }
}

.github:hover {
  cursor: pointer;
}

.textDetails h2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.projectsContainer {
  padding: 20px 20px 0 20px;
  display: flex;
  flex-direction: column;
  /* height: 100vh; */
}

.projectsContainer h1 {
  padding-left: 5%;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}

.imgContainer {
  position: relative;
  width: 65%;
}

.imgImage {
  display: block;
  width: 100%;
}

.imgOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f5e19f;
  flex-direction: column;
  opacity: 0;
  transition: opacity 0.25s;
  border-radius: 10px;
}

.imgContainer p {
  text-align: justify;
  font-size: 16px;
}

.iconsContainer {
  display: flex;
  width: 60%;
  justify-content: space-around;
  padding-top: 40px;
}

.icons {
  cursor: pointer;
}

.techEl {
  color: #6a6b6a;
  border: 1.3px solid #fdb100;
  border-radius: 5px;
  border-radius: 10px;
  padding: 5px;
  margin-right: 10px;
  margin-top: 5px;
  font-size: 12px;
}

.techStack {
  display: flex;
  flex-wrap: wrap;
}

.headingUnderline {
  width: 115px;
  height: 2px;
  background-color: var(--deep-yellow);
  margin-left: 5%;
}

@media only screen and (min-width: 760px) and (max-width: 810px) {
  .projectsContainer h1 {
    font-size: 50px;
  }
  .headingUnderline {
    width: 150px;
  }

  .textDetails h2 {
    font-size: 18px;
  }

  .textDetails p {
    font-size: 20px;
    margin-top: 0;
  }

  .techEl {
    font-size: 16px;
    margin-top: 0px;
  }

  .imgImage {
    width: 90%;
  }
}

@media only screen and (min-width: 810px) {
  .projectsContainer h1 {
    font-size: 55px;
  }
  .techEl {
    font-size: 20px;
  }
  .textDetails p {
    font-size: 24px;
  }

  .textDetails h2 {
    font-size: 24px;
  }
}

@media only screen and (min-width: 1025px) {
  .temp {
    width: 90%;
  }

  .contentContainer {
    flex-direction: row;
    justify-content: space-around;
  }

  .projectsContainer {
    padding-block: 20px 50px;
    /* addition below.... */
    height: 100vh;
  }

  .imgContainer {
    padding-top: 2%;
    border-radius: 20px;
    margin-left: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80%;
  }

  .imgImage {
    width: 100%;
    object-fit: cover;
  }

  .textDetails {
    width: 90%;
  }
}

/* .imgOverlay > * {
  transform: translateY(20px);
  transition: transform 0.25s;
}

.imgOverlayBlur {
  backdrop-filter: blur(5px);
}

.imgOverlay:hover {
  opacity: 1;
}

.imgOverlay > * {
  transform: translateY(0px);
} */

/* UNUSED CSS BELOW */

.experienceContainer {
  flex: 1;
  width: 100%;
  height: 80vh;
  border: 1px solid red;
}

.sliderContainer {
  flex: 1;
  width: 100%;
  height: 80vh;
  border: 1px solid red;
}

.slider {
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  transition: background-image 0.5s ease-in-out;
}

.centerContent {
  flex: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  /* display: grid; */
  /* place-items: center; */
  /* text-align: justify; */
  /* text-align-last: center; */
  transition: opacity 0.5s ease-in-out;
}

.backBtn {
  flex: 5%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.6);
  display: grid;
  place-items: center;
  color: white;
  cursor: pointer;
}

.nextBtn {
  flex: 5%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.6);
  display: grid;
  place-items: center;
  color: white;
  cursor: pointer;
}
